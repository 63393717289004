import { useState, useEffect } from "react";
import { Col, Row, Alert, Button } from "react-bootstrap";
import { ArrowRightCircle } from 'react-feather';

export const Newsletter = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (status === 'success') clearFields();
  }, [status]);

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
    email.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email
    });
  };

  const clearFields = () => {
    setEmail('');
  };

  const handleButtonClick = () => {
    window.location.href = 'https://dojo.scriptingdojo.com';
  };

  return (
      <Col lg={12}>
        <div className="newsletter-bx wow slideInUp">
        <Row>
          <Col lg={12} md={8} xl={7}>
            <h3>Para aprender más de Programación Ingresa a nuestro DOJO 🈴</h3>
            {status === 'sending' && <Alert>Sending...</Alert>}
            {status === 'error' && <Alert variant="danger">{message}</Alert>}
            {status === 'success' && <Alert variant="success">{message}</Alert>}
          </Col>
          <Col md={4} xl={5} className="d-flex justify-content-end">
            <Button variant="primary" onClick={handleButtonClick}>Ingresa <ArrowRightCircle size={20} /></Button>
            </Col>
          </Row>
        </div>
      </Col>
  );
};
